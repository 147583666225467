.static-view {
	margin-top: var(--page-margin-top);

	&.Anreise-v1 {
		.logo-container {
			.logo-bl {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				max-width: 1200px;
				margin: 0 auto;
				justify-content: center;

				.item {
					width: 330px;
					margin: 0 20px;

					.logo-img {
						border: 2px solid var(--color-primary);
						min-height: 200px;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							max-width: 250px;
							width: 100%;
						}
					}

					.link {
						margin-bottom: 30px;
						display: inline-block;
					}

				}
			}
		}
	}
}